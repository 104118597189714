import React from 'react';
import './hero.scss';

export class Hero extends React.Component {
  public render(): React.ReactElement {
    return (
      <div className="liquidHero" title="Vive hoy y planifica el mañana." >
        <section className="hero">
          <h1 className="title">Vive hoy y planifica el mañana.</h1>
          <p className="context">Ayudamos a tomar decisiones informadas e inteligentes sobre su planificación de inversiones para la jubilación, seguro de vida o gastos médicos.</p>
        </section>
      </div>
    );
  }
}

export default Hero;