import React from 'react';
import medical from '../../assets/images/health-insurance.svg';
import piggyBank from '../../assets/images/piggy-bank.svg';
import insurance from '../../assets/images/insurance.svg';
import Helpers from '../../helpers/core.helpers';
import './services.scss';

export class Services extends React.Component {
  public render(): React.ReactElement {
    return (
      <div className="liquidServices">
        <section className="services">
          <div className="cardMeds">
            <img src={medical} alt="" className="icon" />
            <h1>Gastos Médicos Mayores</h1>
            <p>Protección en los gastos en que incurras con motivo de atención médica, a nivel nacional, por ejemplo: honorarios por consultas médicas, tratamientos médicos y/o quirúrgicos, sala de operaciones, recuperación y curaciones; análisis de laboratorio, medicamentos, entre otros.</p>
            <div className="action">
              <button onClick={Helpers.getHelpView} className="askCallBtn small">Solicitar asesoria</button>
            </div>
          </div>
          <div className="cardRetire">
            <img src={piggyBank} alt="" className="icon" />
            <h1>Seguro para el retiro</h1>
            <p>Cuando te jubiles, tu costo de vida cambiará. Algunos gastos aumentarán y otros disminuirán. Tu estilo de vida y tu salud influirán en la forma en que elijas gastar tu dinero. Evaluar tus costos de vida y comprender tus ingresos son esenciales al tomar una decisión sobre qué forma de anualidad debes seleccionar.</p>
            <div className="action">
              <button onClick={Helpers.getHelpView} className="askCallBtn small">Solicitar asesoria</button>
            </div>
          </div>
          <div className="cardEnsurance">
            <img src={insurance} alt="" className="icon" />
            <h1>Seguro de vida</h1>
            <p>Con el seguro de vida obtienes mucho más que cobertura durante un periodo de tiempo determinado. Obtienes un seguro temporal que va más allá de proteger a tu familia a corto plazo: te coloca a ti y a tus seres queridos en el camino hacia la preparación financiera en el futuro.</p>
            <div className="action">
              <button onClick={Helpers.getHelpView} className="askCallBtn small">Solicitar asesoria</button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Services;