import React from 'react';
import Helpers from '../../helpers/core.helpers';
import './customize.scss';

export class Customize extends React.Component {
  public render(): React.ReactElement {
    return (
      <div className="liquidCustomize">
        <section className="customize">
          <h1 className="title">Obtén un <strong>servicio personalizado</strong> para guiarte hoy a tomar las mejores decisiones para tu futuro.</h1>
          <p>Nuestro objetivo es ayudarte a adquirir un plan adecuado dependiendo de tus necesidades y tus objetivos. Te orientaremos en el paso a paso aclarando todas tus dudas al momento.</p>
          <div className="action">
            <button onClick={Helpers.getHelpView} className="askCallBtn">Solicitar asesoria</button>
          </div>
        </section>
      </div>
    );
  }
}

export default Customize;