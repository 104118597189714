import React from 'react';
import medical from '../../assets/images/health-insurance.svg';
import piggyBank from '../../assets/images/piggy-bank.svg';
import lifeInsurance from '../../assets/images/insurance.svg';
import './leads.scss';

interface LeadForm {
  fullname: string;
  email: string;
  phone: string;
  service: string;
}
interface LeadState {
  error: string;
}
export class Leads extends React.Component<{}, LeadState> {
  private _error = '';
  public _lead: LeadForm;

  constructor(props: any) {
    super(props);
    this.state = {
      error: ''
    };
    this._lead = {
      fullname: '',
      email: '',
      phone: '',
      service: ''
    };

    this.onFullnameChangeHandler = this.onFullnameChangeHandler.bind(this);
    this.onEmailChangeHandler = this.onEmailChangeHandler.bind(this);
    this.onPhoneChangeHandler = this.onPhoneChangeHandler.bind(this);
    this.onServiceChangeHandler = this.onServiceChangeHandler.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  public onFullnameChangeHandler(event: any) {
    this._lead.fullname = event.target.value;
  }

  public onEmailChangeHandler(event: any) {
    this._lead.email = event.target.value;
  }
  public onPhoneChangeHandler(event: any) {
    this._lead.phone = event.target.value;
  }
  public onServiceChangeHandler(event: any) {
    event.preventDefault();
    this.clearServiceSelected();
    this._lead.service = event.currentTarget.dataset.service
    event.currentTarget.classList.add('selected');
  }

  public clearServiceSelected() {
    const services = window.document.getElementById('serviceList')!.children;
    Array.from(services).forEach((item) => {
      item.children[0].classList.remove('selected');
    });
  }

  public handleFormSubmission(event: any) {
    event.preventDefault();
    // Resets errors before validation
    this._error = '';
    // Validate phone number
    if (this._lead.phone.length === 0) {
      this._error = 'Debe ingresar su teléfono a 10 dígitos.';
    }
    // Validate email is entered
    if (this._lead.email.length === 0) {
      this._error = 'Debe ingresar un correo electrónico válido.';
    }
    // Validate fullname is entered
    if (this._lead.fullname.length === 0) {
      this._error = 'Debe ingresar su nombre completo.';
    }
    // Validate service of interest is selected
    if (this._lead.service.length === 0) {
      this._error = 'Debe seleccionar un servicio de interes.';
    }
    // Render if error exists
    if (this._error.length > 0) {
      this.setState({ error: this._error });
    }
    // Submit form once everything is validated
    if (this._error.length === 0) {
      console.log(this._lead);
      // Clear error in render
      this.setState({ error: '' });
      // Send the request to post data
      fetch('https://kbalconsultores.com.mx/leads', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(this._lead)
      })
        .then((res) => {
          this.setState({ error: 'Su solicitud ha sido recibida, un asesor se pondra en contacto lo antes posible.' });
        })
        .catch((err) => {
          this.setState({ error: 'Ha ocurrido un error en el sistema, por favor intete más tarde.' });
        });
    }
  }

  public clearFormValues() {
    this.clearServiceSelected();
    (window.document.getElementById('leadForm') as any).reset();
  }

  public render(): React.ReactElement {
    return (
      <div className="liquidLeads">
        <section className="leads">
          <div id="getHelp" className="title">
            <h1>Planeemos juntos tu futuro hoy.</h1>
          </div>
          <div className="contactBox">
            <div className="contactForm">
              <p className="question">¿Qué servicio te interesa?</p>
              <div id="serviceList" className="servicesList">
                <div className="serviceMedical">
                  <a href="/" onClick={this.onServiceChangeHandler} data-service="Medical Insurance"><img src={medical} alt="" /></a>
                  <p>Gastos<br />médicos</p>
                </div>
                <div className="serviceBank">
                  <a href="/" onClick={this.onServiceChangeHandler} data-service="Bank Savings"><img src={piggyBank} alt="" /></a>
                  <p>Seguro para<br />el retiro</p>
                </div>
                <div className="serviceLife">
                  <a href="/" onClick={this.onServiceChangeHandler} data-service="Life Insurance"><img src={lifeInsurance} alt="" /></a>
                  <p>Seguro<br />de vida</p>
                </div>
              </div>
              <form id="leadForm" className="leadForm" action="post">
                <div className="fullname">
                  <label htmlFor="fullname">Nombre Completo</label>
                  <input type="text" name="fullname" onChange={this.onFullnameChangeHandler} id="fullname" placeholder="Ingresa tu nombre completo" />
                </div>
                <div className="email">
                  <label htmlFor="email">Correo electrónico</label>
                  <input type="email" name="email" onChange={this.onEmailChangeHandler} id="fullname" placeholder="Ingresa un correo electrónico válido" />
                </div>
                <div className="phone">
                  <label htmlFor="phone">Teléfono de contacto</label>
                  <input type="text" name="phone" onChange={this.onPhoneChangeHandler} id="phone" placeholder="555 444 33 22" />
                </div>
                <div className="error">
                  <p>{this.state.error}</p>
                </div>
                <div className="action">
                  <button onClick={this.handleFormSubmission} className="submitFormBtn">Solicitar asesoria</button>
                </div>
              </form>
            </div>
            <div className="contactInfo">
              <h1>Seguro de gastos médicos mayores</h1>
              <p>La prima cotizada está destinada a un cliente nuevo; si tiene una póliza de seguro existente con nosotros, contáctenos a través de su asesor para obtener los términos de renovación y la prima.</p>
              <p>¿Seguro vencido? No se preocupe, ahora puede seguir adelante y ponerse en marcha más rápido de lo necesario para prepararse para el trabajo por la mañana.</p>
              <ul>
                <li>Conveniencia Obtenga un seguro en cualquier momento y en cualquier lugar</li>
                <li>Disponibilidad 24/7</li>
                <li>Una forma mejor y más rápida de obtener un seguro</li>
                <li>Proceso simple de 3 pasos sin esperas en multitudes o filas</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Leads;