import React from 'react';
import Helpers from '../../helpers/core.helpers';
import './steps.scss';

export class Steps extends React.Component {
  public render(): React.ReactElement {
    return (
      <div className="liquidSteps">
        <section className="steps">
          <div className="title">
            <h1>Con solo 3 simples pasos obtienes mayor claridad y seguridad en tu porvenir.</h1>
          </div>
          <div className="process">
            <div className="one">
              <h1>Contáctanos o solicita una llamada personalizada</h1>
              <p>Recibe una llamada personalizada de nuestros mejores agentes</p>
            </div>
            <div className="two">
              <h1>Te asesoramos en los diferentes planes</h1>
              <p>Te guiaremos paso a paso a elegir el plan adecuado para tu futuro</p>
            </div>
            <div className="three">
              <h1>Obtienes protección y seguridad para tu futuro</h1>
              <p>Obtienes tu plan con toda la información necesaria para tu protección.</p>
            </div>
          </div>
          <div className="action">
            <button onClick={Helpers.getHelpView} className="askCallBtn">Solicitar asesoria</button>
          </div>
        </section>
      </div>
    );
  }
}

export default Steps;