import React from 'react';
import logo from '../../assets/images/logo.svg';
import { Helpers } from '../../helpers/core.helpers';
import './header.scss';

export class Header extends React.Component {
  public render(): React.ReactElement {
    return (
      <div className="liquidWrapper">
        <section className="header">
          <div className="logo">
            <a href="/">
              <img src={logo} width="136px" height="auto" alt="Kbal Consultores" />
            </a>
          </div>
          <div className="action">
            <button onClick={Helpers.getHelpView} className="askCallBtn small">Solicitar una llamada</button>
          </div>
        </section>
      </div>
    );
  }
}

export default Header;