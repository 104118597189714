import React from 'react';
import Helpers from '../../helpers/core.helpers';
import './portfolio.scss';

export class Portfolio extends React.Component {
  public render(): React.ReactElement {
    return (
      <div className="liquidPortfolio">
        <section className="portfolio">
          <div className="slider">
            <div className="one"></div>
            <div className="two"></div>
          </div>
          <div className="toYou">
            <h1>No solo nos interesamos en seguros, nos interesa tu bienestar.</h1>
            <ul>
              <li>Tenemos amplia experiencia asesorando a cientos de clientes a obtener un futuro más claro y seguro para ellos y sus familias.</li>
              <li>Trabajamos con las mejores marcas asociadas para darte la mayor protección y calidad de servicio en México.</li>
            </ul>
            <div className="action">
              <button onClick={Helpers.getHelpView} className="askCallBtn">Solicitar asesoria</button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Portfolio;