import React from 'react';
import logo from '../../assets/images/logo.svg';
import facebook from '../../assets/images/networks/facebook.svg';
import instagram from '../../assets/images/networks/instagram.svg';
import './footer.scss';

export class Footer extends React.Component {
  public render(): React.ReactElement {
    return (
      <div className="liquidFooter">
        <section className="footer">
          <div className="socialInfo">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <p>Ayudamos a tomar decisiones informadas sobre inversiones para la jubilación, seguro de vida o gastos médicos.</p>
            <div className="networks">
              <div className="instagram" title="instagram">
                <a href="https://instagram.com/kbalconsultores?igshid=qpgr8k7z512n" target="_blank" rel="noreferrer">
                  <img src={instagram} alt="" />
                </a>
              </div>
              <div className="facebook" title="facebook">
                <a href="https://www.facebook.com/101069328565448/" target="_blank" rel="noreferrer">
                  <img src={facebook} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="contact">
            <h1>Contáctanos</h1>
            <div className="email">
              <a href="mailto:gerardo.alvarez@kbalconsultores.com.mx">gerardo.alvarez@kbalconsultores.com.mx</a>
            </div>
            <div className="phone">
              <a href="tel:(222) 954 05 94">(222) 954 05 94</a>
            </div>
            <div className="copyright">
              © 2020 Kabal Seguros, todos los derechos reservados.
          </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Footer;