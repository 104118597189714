import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Header from './components/header/header';
import Hero from './components/hero/hero';
import Notice from './components/notice/notice';
import Customize from './components/customize/customize';
import Steps from './components/steps/steps';
import Portfolio from './components/portfolio/portfolio';
import Services from './components/services/services';
import Leads from './components/leads/leads';
import Footer from './components/footer/footer';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Header />
    <Hero />
    <Notice />
    <Customize />
    <Steps />
    <Portfolio />
    <Services />
    <Leads />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
