import React from 'react';
import image from "../../assets/images/notice.png";
import './notice.scss';

export class Notice extends React.Component {
  public render(): React.ReactElement {
    return (
      <section className="notice">
        <div className="left">
          <h1 className="title">Te enfrentas a un futuro financiero incierto.</h1>
          <p className="context">En México más de 30.7 millones de jóvenes no contarán con una pensión de jubilación. El 65% de los jóvenes entre 15 y 29 años no han pensado en ahorrar para el momento de su jubilación sin advertir que en la actualidad al menos el 26% de los adultos mayores en México no reciben ningún tipo de ingreso, mientras que los que sí trabajan reciben menos de dos salarios mínimos.</p>
          <p className="closedText">En <strong>Kbal Consultores</strong> podemos guiarte en obtener el fondo adecuado para tu retiro y con ello asegurar una buena calidad de vida para esta importante etapa de tu vida.</p>
        </div>
        <div className="right">
          <img src={image} alt="" />
        </div>
      </section>
    );
  }
}

export default Notice;